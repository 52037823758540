import React from "react";
import { useKey } from "../../context/keycontext";

export default function BtnChange(props) {
  const { id } = props;
  const { changeNum, setGameFlag } = useKey();
  let operator;

  const handleClick = (e) => {
    e.preventDefault();
    setGameFlag(false);
    // changeNum(e.target.id);
    setTimeout(() => {
      changeNum(e.target.id);
    }, 1500);
  }

  if (id === "plus") {
    operator = "higher";
  } else {
    operator = "lower";
  }

  return (
    <div className={`btn-body btn-${operator}`}>
      <span onClick={handleClick} id={id} className="btn-change">{operator}&nbsp;&nbsp;
        <img src={`/button/ar_${operator}.png`} alt='buttonarrow' />
      </span>
    </div>
  )
}
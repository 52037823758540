import React from 'react';

const Flower = (props) => {
    const {flower} = props;
    let flower_src = '';
    switch (flower) {
        case 'spade': flower_src='/cardpattern/spade.png'; break;
        case 'clover': flower_src='/cardpattern/clover.png'; break;
        case 'diamond': flower_src='/cardpattern/diamond.png'; break;
        default: flower_src='/cardpattern/heart.png'; break;
    }
    return (
        <div>
            <img className='card-flower' src={flower_src} alt={flower}/>
        </div>
    );
}

export default Flower;

import React from 'react';

const Title = (props) => {
    return(
        <div className='header-title'>
            {props.title}
        </div>
    )
}

export default Title;
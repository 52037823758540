import React from 'react';

const Footer = (props) => {
    return(
        <footer className='app-footer'>
            {props.children}
        </footer>
    )
}

export default Footer; 
import React from 'react';

const Number = (props) => {
    const {number, flower} = props;
    let number_src = '';
    if (flower === 'heart' || flower === 'diamond') {
        number_src = '/cardpattern/red_'
    } else {
        number_src = '/cardpattern/blc_'
    }
    return(
        <div>
            <img className='card-number' src={`${number_src}${number}.png`} alt='card number' />
        </div>
    );
}

export default Number;
export const products = [
    {
        product: "Product 1",
        name: "Hardwood Plywood 9mm x 2440mm x 1220mm",
        // name: "Plywood",
        price: 21.07,
        source: "product_1.png"
    },
    {
        product: "Product 2",
        name: "C24 Regularised Treated Timber (4x2) x 4.8m",
        // name: "Timber",
        price: 6.07,
        source: "product_2.png"
    },
    {
        product: "Product 3",
        name: "MOT Type 1 Bulk Bag (850KG)",
        // name: "MOT Type 1",
        price: 37.11,
        source: "product_3.png"
    },
    {
        product: "Product 4",
        name: "Oak Sleeper 100mm x 200mm x 2.4m",
        // name: "Oak Sleeper",
        price: 40.13,
        source: "product_4.png"
    },
    {
        product: "Product 5",
        name: "General Purpose Cement 25kg",
        // name: "Cement",
        price: 6.35,
        source: "product_5.png"
    },
    {
        product: "Product 6",
        name: "10L PVA Builders Glue",
        // name: "Builders Glue",
        price: 21.07,
        source: "product_6.png"
    },
    {
        product: "Product 7",
        name: "K2 Stelrad Convector  Radiator 700mm x 1800mm",
        // name: "Radiator",
        price: 192.32,
        source: "product_7.png"
    },
    {
        product: "Product 8",
        name: "Solid Concrete Block, 7.3N  (440mm x 140mm x 215mm)",
        // name: "Block",
        price: 1.57,
        source: "product_8.png"
    },
    {
        product: "Product 9",
        name: "Tapered Edge Plasterboard - 2400 x 1200 x 12.5mm",
        // name: "Plasterboard",
        price: 6.35,
        source: "product_9.png"
    },
    {
        product: "Product 10",
        name: "JCB 15t 360 Excvatator",
        // name: "Excvatator",
        price: 54100,
        source: "product_10.png"
    },
    {
        product: "Product 11",
        name: "Manitou MT420 Compact Telehandler",
        // name: "Telehandler",
        price: 36900,
        source: "product_11.png"
    },
    {
        product: "Product 12",
        // name: "Greggs Sausage Roll ",
        name: "Greggs Sausage Roll ",
        price: 1.05,
        source: "product_12.png"
    },
    {
        product: "Product 13",
        name: "DeWalt DCD709N 18V XR Brushless Combi Drill",
        // name: "Combi Drill",
        price: 69,
        source: "product_13.png"
    },
    {
        product: "Product 14",
        name: "Golden Gravel Bulk Bag (85KG)",
        // name: "Golden Gravel",
        price: 64.13,
        source: "product_14.png"
    },
    {
        product: "Product 15",
        name: "Tesla Model S",
        price: 39990,
        source: "product_15.png"
    },
    {
        product: "Product 16",
        name: "Hamleys Cuddly Toy!",
        // name: "Cuddly Toy!",
        price: 30,
        source: "product_16.png"
    },
    {
        product: "Product 17",
        name: "White Sealant CT1 Tube 290ml",
        // name: "Sealant",
        price: 10.31,
        source: "product_17.png"
    },
    {
        product: "Product 18",
        name: `London Pre-finished OAK Door (78" x 33")`,
        // name: `OAK Door`,
        price: 143,
        source: "product_18.png"
    },
    {
        product: "Product 19",
        name: "Closeboard Fence Panel - 6ft x 6ft ",
        // name: "Fence Panel",
        price: 47.13,
        source: "product_19.png"
    },
    {
        product: "Product 20",
        name: "Plastic Tile Spacers,  Pack of 1000",
        // name: "Plastic Spacers",
        price: 3.37,
        source: "product_20.png"
    },
    {
        product: "Product 21",
        name: "4x Stella Artois (440ml)",
        // name: "Artois",
        price: 5.15,
        source: "product_21.png"
    },
    {
        product: "Product 22",
        name: "Common Engineering Brick",
        // name: "Engineering",
        price: 0.47,
        source: "product_22.png"
    },
    {
        product: "Product 23",
        name: "Standard MDF 2440mm x 1220mm x 9mm",
        // name: "MDF",
        price: 22.44,
        source: "product_23.png"
    },
    {
        product: "Product 24",
        name: "PIR Insulation Board - 100mm x 2400mm x 1200mm",
        // name: "PIR Insulation Board",
        price: 38.11,
        source: "product_24.png"
    }
];
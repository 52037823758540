import React from 'react';
import { useKey } from '../../context/keycontext';

const BtnModal = (props) => {
    const {setScore, setVisible} = useKey();
    const {content} = props;

    const PlayAgain = () => {
        setScore(0);
        setVisible(0);
    }

    let btn_style;
    if (content === 'play again') { 
        btn_style = 'btn-play';
        return(
            <div className={btn_style} onClick={PlayAgain}>
                <a href='/'>{content}</a>
            </div>    
        );
    } else {
        btn_style = 'btn-back';
        return(
            <div className={btn_style} >
                <a href='/'>{content}</a>
            </div>
        )
    }
}

export default BtnModal;
import React from 'react';
import { useKey } from '../../context/keycontext';

const BtnScore = () => {
    const {score} = useKey();
    return (
        <div className='btn-body btn-score' >
            <span>Score : {score}</span>
        </div>
    );
}

export default BtnScore;
import React from 'react';
import { useKey } from '../../context/keycontext';
import ModalContent from './modal-content';

const Modal = () => {
    const {score, visible} = useKey();
    let className='';
    if(visible) {
        className="visible";
    } else {
        className="invisible";
    }

    return(
        <div className={`modal ${className}`}>
            <ModalContent />
        </div>

    );
}

export default Modal;
import React from 'react';
import CardId from './card-id';
import CardContent from './card-content';

const Card = (props) => {
    const { title, content, val, flower, number, back_img } = props;
    let cardClass = '';
    if(flower === "heart" || flower === "diamond") {
        cardClass = 'card-red';
    } else cardClass = 'card-black';
    
    return(
        <div className={`card ${cardClass}`}>
            <CardId flower={flower} number={number} pos='top' />
            <CardContent title={title} content={content} val={val} back_img = {back_img} />
            <CardId flower={flower} number={number} pos='bottom' />
        </div>
    );
}

export default Card;
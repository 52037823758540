import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { products } from '../utils/randomData';
import { randomNumbers } from '../utils/randomArray';

const KeyContext = createContext();

export default function KeyProvider(props) {
    const [score, setScore] = useState(0);
    const [randomNo, setRandomNo] = useState([]);
    const [visible, setVisible] = useState(0);
    const [val, setVal] = useState([]);
    const [status, setSatus] = useState();
    const [count, setCount] = useState(0);
    const [randomProduct, setRandomProduct] = useState([]);

    const [gameFlag, setGameFlag] = useState(true);

    let result;
    let num;

    const changeNum = useCallback((operator) => {
        setGameFlag(true);
        setCount(count+1);

        if(operator === 'plus') {
            num = true;
        } else {
            num = false;
        }
        
        if(products[val[0]].price < products[val[1]].price) {
            result = true;
        }  else {
            result = false;
        }
        
        if(num ^ result) {
            if(count < 9) {
                setSatus(0);
            } else setSatus(1);

            setCount(0);
            setVisible(1);

        } else {
            setScore(score + 1);
            setVisible(0);
        }
        if (count > 8) {
            setVisible(1);
            setSatus(1);
            setCount(0);
        }
    }, [val, gameFlag]);

    useEffect(()=>{
        if (count === 0) {
            setRandomNo(randomNumbers);
        }else{
            setVal([randomNo[count], randomNo[count + 1]]);
            setRandomProduct([products[randomNo[count]], products[randomNo[count+1]]]);
        }
    }, [count]);

    useEffect(()=>{
        setVal([randomNo[0], randomNo[1]]);
        setRandomProduct([products[randomNo[0]], products[randomNo[1]]]);
    }, [randomNo]);
    
    const providerValue = useMemo(() => {
        return {
            score,
            val,
            visible,
            status,
            randomProduct,
            gameFlag,
            setScore,
            setVisible,
            changeNum,
            setVal,
            setRandomProduct,
            setGameFlag
        }
    }, [num, score, val, visible, randomProduct, gameFlag, changeNum, setVal, setGameFlag]);

    useEffect(()=>{
        console.log(randomNo);
        console.log(val);
    },[val])

    return (
        <KeyContext.Provider value={providerValue}>
            {props.children}
        </KeyContext.Provider>
    );
}

export const useKey =  () => useContext(KeyContext);



import React from 'react';
import { useKey } from '../../context/keycontext';

const Content = (props) => {
    const {visible} = useKey();
    let contentback = '';
    if (visible) {
        contentback = 'content-back'
    }
    return (
        <div className={`app-content ${contentback}`}>
            {props.children}
        </div>
    );
}

export default Content;
import React from 'react';
import Flower from './flower';
import Number from './number';

const CardId  = (props) => {
    const {flower, number, pos} = props;
    return (
        <div className={`card-id ${pos}`}>
            <Number number={number} flower={flower}/>
            <Flower flower={flower} />
        </div>
    );
}

export default CardId;
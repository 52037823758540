import React from 'react';
import { useKey } from '../../context/keycontext';

export default function Layout(props) {
    const {visible} = useKey();
    let blur = '';
    if (visible) blur = 'blur'
    return(
        <div className={`App`}>
            {props.children}
        </div>
    );
}
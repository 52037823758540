const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const numbers = Array.from({length: 24}, (_, i) => i);

shuffle(numbers);

export const randomNumbers = numbers.slice(0, 11); 

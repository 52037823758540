import React from 'react';
import BtnChange from './btnchange';
import BtnScore from './btnscore';

const BtnGroup = () => {
    return(
        <div className='btngroup'>
            <BtnScore />
            <BtnChange id="plus" />
            <BtnChange id="minus" />
        </div>
    );
}

export default BtnGroup;    
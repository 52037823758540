import React from 'react';
import Image from './image';

function commafy( num ) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
}

const CardContent = (props) => {
    const {title, val, content, back_img} = props;
    return(
        <div className='card-content'>
            <Image img_src={back_img} />
            <p className='card-title'>{title}</p>
            {/* <p className='card-normal'>{ (content.length > 0)?"has":"" }</p> */}
            <p className='card-val'>{commafy(val)}</p>
            {/* <p className='card-normal'>{content}</p> */}
        </div>
    );
}

export default CardContent
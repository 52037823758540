import React from 'react';

const Image = (props) => {
    const {img_src} = props;
    return(
        <div className='card-img' >
            <img src={img_src} alt="card image" />
        </div>
    );
}

export default Image;
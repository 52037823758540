import React, {useEffect, useState} from 'react';

import '../App.css';
import Layout from '../components/layout/layout';
import Header from '../components/layout/header';
import Logo from '../components/header/logo';
import Content from '../components/layout/content';
import Footer from '../components/layout/footer';
import Title from '../components/header/title';
import Card from '../components/card/card';
import BtnGroup from '../components/button/btngroup';
import { useKey } from '../context/keycontext';
import Modal from '../components/modal/modal';
import { Form } from 'react-router-dom';

function App() {
  const {val, randomProduct, gameFlag} = useKey();
  const [image1, setImage1] = useState("/cardimage/Ellipse18.png");
  const [image2, setImage2] = useState("/cardimage/Ellipse19.png");

  useEffect(()=>{
    setImage1("/cardimage/" + randomProduct[0]?.source);
    setImage2("/cardimage/" + randomProduct[1]?.source);
  },[randomProduct]);
  
  return (
    <>
    <Layout>
      <Header>
        <Logo />
        <Title title = "The Materials Price is Right"/>
      </Header>
      <Content>
        <Card title={randomProduct[0]?.name} val={`£` + randomProduct[0]?.price} content="Average Monthly Searches" flower='heart' number='8' back_img={image1} />
        <BtnGroup />
        <Card title={randomProduct[1]?.name} val={gameFlag?``:(`£` + randomProduct[1]?.price)} content="" flower='spade' number='6' back_img={image2} />
      </Content>
      <Footer>
      </Footer>
    </Layout>
    <Modal />
    </>
  );
}

export default App;

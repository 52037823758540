import React from 'react';
import { useKey } from '../../context/keycontext';
import BtnModal from './btnmodal';

const ModalContent = () => {
    const {status, score} = useKey();
    let title;
    let content;
    let modal_class
    let top=10;
    if (status) {
        title = 'Nice one';
        content = `You have won!`;
        modal_class = 'won';
        if(score < top) {
            content = `Not quite the top score. The top score is ${top}.`
        }
    } else {
        title = 'You lose';
        content = `The average score is 6. Have another go.`
        modal_class = 'lose';
    }
    return(
        <div className={`modal-content ${modal_class}`}>
            <h1>{title}</h1>
            <h3>Your score</h3>
            <h2>{score}</h2>
            <p>{content}</p>
            <div className='btn-modal-group'>
                <BtnModal content="back to menu" />
                <BtnModal content="play again" />
            </div>
        </div>
    );
}

export default ModalContent;